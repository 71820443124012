/*@jsxRuntime automatic @jsxImportSource react*/
import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import nameplateFeature from "/public/images/nameplates/feature.svg";
import nameplatesGraphik from "/public/images/nameplates/graphik.svg";
import nameplatesAntique from "/public/images/nameplates/antique no 6.svg";
import nameplatesOriginal from "/public/images/nameplates/original sans.svg";
import nameplatesSeance from "/public/images/nameplates/seance.svg";
import nameplatesLeJeune from "/public/images/nameplates/le jeune.svg";
import nameplatesOrleans from "/public/images/nameplates/orleans.svg";
import nameplatesOrientation from "/public/images/nameplates/orientation.svg";
import nameplatesLyon from "/public/images/nameplates/lyon.svg";
import nameplatesEugenia from "/public/images/nameplates/eugenia.svg";
import nameplatesProxy from "/public/images/nameplates/proxy.svg";
export const meta = {
  title: "From the Editor",
  type: "from-the-editor",
  paragraphs: "indents",
  slug: "from-the-editor",
  families: {
    text: 1,
    headings: 2,
    pullquotes: 2,
    byline: 1,
    bio: 3,
    credits: 3,
    copyright: 3
  },
  layouts: {
    Feature: {
      fonts: ["Review", "Feature Display", "Feature Deck", "Feature Text"],
      nameplate: nameplateFeature,
      excerpt: true
    },
    Graphik: {
      fonts: ["Graphik XXX Condensed", "Graphik Wide", "Guardian Egyptian Text"],
      nameplate: nameplatesGraphik,
      excerpt: true
    },
    "Antique No 6": {
      fonts: ["Caslon Rounded", "Antique No 6", "Austin News Text"],
      nameplate: nameplatesAntique
    },
    "Original Sans": {
      fonts: ["Caslon Italian", "Original Sans", "Marr Sans", "Caslon Ionic"],
      nameplate: nameplatesOriginal
    },
    Seance: {
      fonts: ["Seance", "Terza Reader"],
      nameplate: nameplatesSeance
    },
    "Le Jeune": {
      fonts: ["Caslon Doric Extended", "Le Jeune Deck", "Le Jeune Text Stencil", "Feature Text"],
      nameplate: nameplatesLeJeune
    },
    Orleans: {
      fonts: ["Dala Prisma", "Orleans", "Publico Text", "Atlas Grotesk"],
      nameplate: nameplatesOrleans
    },
    Orientation: {
      fonts: ["Orientation", "Moulin"],
      nameplate: nameplatesOrientation
    },
    Lyon: {
      fonts: ["Lyon Display", "Lyon Text"],
      nameplate: nameplatesLyon,
      excerpt: true
    },
    Eugenia: {
      fonts: ["Eugenio Sans", "Eugenia Headline", "Darby Serif Text"],
      nameplate: nameplatesEugenia,
      noteHeader: _jsx("h2", {
        className: "editors-note-title",
        children: "A note from the editor, Caren Litherland"
      })
    },
    Proxy: {
      fonts: ["Proxy", "Atlas Grotesk", "Atlas Typewriter"],
      nameplate: nameplatesProxy,
      excerpt: true
    }
  }
};
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      h2: "h2",
      p: "p",
      a: "a",
      h3: "h3"
    }, props.components);
    return _jsxs("div", {
      className: "notes",
      children: [_jsx("div", {
        className: "editors-note-header",
        children: props.noteHeader ? props.noteHeader : _jsx(_components.h2, {
          className: "editors-note-title",
          children: "From the Editor"
        })
      }), _jsxs("div", {
        className: "editors-note",
        children: [_jsx(_components.p, {
          children: "This is a type specimen. Sort of."
        }), _jsxs(_components.p, {
          children: ["Unless you’re a type designer, you likely felt flummoxed\nthe first time you cast your eyes on a conventional type specimen.\nYour brain probably tried to cull meaning from seemingly endless\nstrings of glyphs, which pushed you to focus on form, not sense.\n“Type specimen language,” ", _jsx(_components.a, {
            href: "https://twitter.com/kellianderson/status/1226184518907899905",
            children: "quipped artist Kelli Anderson"
          }), ",\n“is so! Very colorless green ideas sleep furiously.”"]
        }), _jsxs(_components.p, {
          children: ["There’s a reason for that. By design, these artifacts of Western print\ncapitalism “almost incidentally have textual content.”", _jsx("sup", {
            children: _jsx(_components.a, {
              href: "#johnston-01",
              children: "1"
            })
          }), " Since emerging in\nEurope in the sixteenth century, their primary purpose has been to sell,\nand their lifespans have tended to be short. They were never meant to be great\nworks of literature (although historians like Alastair Johnston have persuasively chronicled their art)."]
        }), _jsxs(_components.p, {
          children: ["Specimens have largely been solipsistic and unidirectional as a genre, broadcasting their “mindless maundering” outward rather than inviting readers in.", _jsx("sup", {
            children: _jsx(_components.a, {
              href: "#johnston-02",
              children: "2"
            })
          }), " That’s because for most of their history, they’ve been confined to print and wedged into a narrow professional niche geared toward printers and their customers — meant to be looked at rather than read."]
        }), _jsxs(_components.p, {
          children: ["That insular status began to change with industrialization and then sped up with the advent\nof phototype. The history of specimens is one of increasing mobility and popular appeal; once\nthey could be (relatively) cheaply mass-produced, folded, and sent through the mail, they became\nless rarefied. With the ascendancy of the commercial web and the development of webfonts, type specimens\nfinally became completely “dematerialized” and “democratized.”", _jsx("sup", {
            children: _jsx(_components.a, {
              href: "#griffin",
              children: "3"
            })
          })]
        }), _jsxs(_components.p, {
          children: ["Commercial Type’s Christian Schwartz and Paul Barnes have always joyfully engaged with the history\nof specimens while putting pressure on that history to ask why specimens are the way they are, and\nhow they might be different. There is a deliberate shift from a unidirectional broadcasting model\nto a more inclusive, dialogic model: Schwartz and Barnes want people to participate in their work.\nThink of the ", _jsx(_components.a, {
            href: "https://commercialtype.com/goods/graphik_specimen_folder",
            children: "Graphik specimen"
          }), ", which included\na sheet of dry-transfer lettering. Or the ", _jsx(_components.a, {
            href: "https://commercialtype.com/goods/broadsheet_and_tabloid_newsprint_specimen",
            children: "type specimen as newspaper"
          }), ":\na pulpy, non-precious, workaday object. Or the ", _jsx(_components.a, {
            href: "https://commercialtype.com/news/thieves_like_us_dino_sanchez_amp_commercial_type",
            children: "three-dimensional specimen for Barnes’s Marian typeface"
          }), ",\nbuilt in collaboration with Dino Sanchez and mounted in a storefront on the corner\nof Kenmare and Elizabeth Streets in Lower Manhattan.", _jsx("sup", {
            children: _jsx(_components.a, {
              href: "#marian",
              children: "4"
            })
          }), " Or Commercial Type’s ", _jsx(_components.a, {
            href: "https://www.instagram.com/p/CeRgvuUORPq/",
            children: "wheat-paste approach"
          }), "\nto specimens, plastered liberally over the hunter-green sidewalk sheds so ubiquitous in New York.\nOr the ", _jsx(_components.a, {
            href: "https://commercialtype.com/news/post_no_bills_tanja_in_use",
            children: "stencils"
          }), ". Or the guerrilla ", _jsx(_components.a, {
            href: "https://commercialtype.com/news/advertising_in_the_new_york_city_subway",
            children: "subway ads"
          }), "."]
        }), _jsxs(_components.p, {
          children: ["The catalyst for the present specimen was a volume published in 1958:  ", _jsx("cite", {
            children: "Linotype-Schriftenreigen"
          }), ", a title one might loosely translate as ", _jsx("cite", {
            children: "A Roundelay of Linotype Typefaces"
          }), ". It’s a diminutive hardcover that fits comfortably in the hand and shows all of German Linotype’s text faces in roughly equivalent settings as text for books. Lighthearted illustrations by Harald Bukor tie the whole together."]
        }), _jsxs(_components.p, {
          children: ["Schwartz and Barnes wondered if they could do something like that, but make it a website and make it mobile-first, since most people access the internet on their phones. They envisioned a type specimen that, like ", _jsx("cite", {
            children: "Linotype-Schriftenreigen"
          }), ", would sit comfortably in readers’ hands. It would be disguised as a single-issue magazine that would show Commercial Type’s text faces at work — a specimen meant to be looked at ", _jsx("em", {
            children: "and"
          }), " read. They decided to commission original writing for it and to encourage readers to choose different typeface combinations for the various texts. When they asked me if I would edit this hypothetical magazine, I enthusiastically said yes."]
        }), _jsx(_components.p, {
          children: "We reached out to a handful of writers. To structure the project, we introduced a few thematic\nconstraints that are near and dear to us: food,  eating, and New York City. Aside from that,\nthe writers who bravely joined us in this venture had free reign to do whatever they wanted.\nThe result is six previously unpublished articles of different genres."
        }), _jsxs(_components.p, {
          children: [_jsx("b", {
            children: "Navneet Alang"
          }), " sets out to interview Helen Rosner about food writing, but the ensuing conversation heads in myriad directions. ", _jsx("b", {
            children: "Helena Fitzgerald"
          }), " contributes an essay about\nwhat makes a place a ", _jsx("em", {
            children: "place"
          }), ". ", _jsx("b", {
            children: "Robin Sloan"
          }), " treats us to a suspenseful work of fiction set\nin the Flatiron District. ", _jsx("b", {
            children: "Diane Chang"
          }), " weaves her Los Angeles upbringing and New York adulthood\ninto her personal variation on the Jamaican patty. ", _jsx("b", {
            children: "Dan Fethke"
          }), " writes a local news story about mutual\naid and the Bronx’s La Morada. And ", _jsx("b", {
            children: "Christian Schwartz"
          }), ", with help from ", _jsx("b", {
            children: "Veronica Gan"
          }), " and\n", _jsx("b", {
            children: "Barnny Lim"
          }), ", offers up a listicle about the Malaysian snacks known as ", _jsx("i", {
            children: "kuih"
          }), ". Abstract\nillustrations by ", _jsx("b", {
            children: "Derrick Schultz"
          }), " and ", _jsx("b", {
            children: "Di-Andre Caprice Davis"
          }), " thread through the\npieces, stylistically concatenating disparate kinds of content."]
        }), _jsx(_components.p, {
          children: "Get lost in the articles. Play with the type. Stick with the recommended pairings or come up with your own. Mess with the\nleading. Change the point size. Experiment with this curious hybrid: a type specimen that is also a magazine — something in\nbetween the two, or a little bit of both."
        }), _jsxs(_components.p, {
          children: ["Schwartz and Barnes have always wanted to put out a magazine, but joke that they lack the focus to sustain a publication beyond a single issue. This is the issue. ", _jsx("b", {
            children: "—Caren Litherland"
          })]
        }), _jsxs("aside", {
          children: [_jsx(_components.h3, {
            children: _jsx("b", {
              children: "Notes"
            })
          }), _jsxs("ol", {
            children: [_jsxs("li", {
              id: "johnston-01",
              children: ["Alastair Johnston, ", _jsx("cite", {
                children: "Alphabets to Order"
              }), " (New Castle, DE: Oak Knoll Press, 2000), 1."]
            }), "\n", _jsx("li", {
              id: "johnston-02",
              children: "Ibid."
            }), "\n", _jsxs("li", {
              id: "griffin",
              children: ["The words are from Dori Griffin, ", _jsx("cite", {
                children: "Type Specimens: A Visual History of Typesetting and Printing"
              }), " (London: Bloomsbury, 2022), 11. In November 2011, Letters from Sweden published the first HTML foundry specimen. The site was notable not only because it was the first type specimen designed in and for the medium of the web, but also because it allowed users to set lines of type themselves. Klim followed suit shortly thereafter. (Some months earlier, under Nick Sherman’s creative direction, the distributor MyFonts had already implemented a live type tester.) There had of course been attempts to make print specimens more “interactive” over the years: consider Letraset’s ", _jsx(_components.a, {
                href: "https://oa.letterformarchive.org/item?workID=lfa_type_0955",
                children: "Typen"
              }), " specimen from the seventies, for example, or Typotheque’s ", _jsx(_components.a, {
                href: "https://www.typotheque.com/books/specimen10",
                children: "Specimen No. 10"
              }), "."]
            }), "\n", _jsxs("li", {
              id: "marian",
              children: ["This specimen-exhibition, titled ", _jsx("cite", {
                children: "Thieves Like Us"
              }), ", to some extent recalls the Fonderie\nOlive’s participation in the International Salon of Paper and Graphic Techniques in Paris in the fifties, where\nenormous specimens of Vendôme and Banco were set up for visitors to circulate through. But note that this was a very\nspecialized audience. The Marian specimen, by contrast, became part of the streetscape, for anyone and everyone\nto experience. See Sandra Chamaret, Julien Gineste, and Sébastien Morlighem, ", _jsx("cite", {
                children: "Roger Excoffon et la fonderie\nOlive"
              }), " (Paris: Ypsilon, 2010)."]
            })]
          })]
        })]
      }), _jsx("h2", {
        className: "credits-title",
        children: "About"
      }), _jsxs("div", {
        className: "credits",
        children: [_jsxs("div", {
          children: [_jsxs("ul", {
            children: [_jsxs("li", {
              children: [_jsx("b", {
                children: "Art direction and design"
              }), " by ", _jsx(_components.a, {
                href: "http://www.nikhilmisra.com",
                children: "Nikhil Misra"
              })]
            }), "\n", _jsxs("li", {
              children: [_jsx("b", {
                children: "Development"
              }), " by ", _jsx(_components.a, {
                href: "https://danielgamage.com",
                children: "Daniel Gamage"
              })]
            }), "\n", _jsxs("li", {
              children: [_jsx("b", {
                children: "Edited"
              }), " by ", _jsx(_components.a, {
                href: "https://lither.land",
                children: "Caren Litherland"
              })]
            })]
          }), _jsx("h3", {
            children: "Illustrations"
          }), _jsxs("ul", {
            className: "indented",
            children: [_jsx("li", {
              children: _jsx(_components.a, {
                href: "https://artificial-images.com",
                children: "Derrick Schultz"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://diandrecapricedavis.com/Home",
                children: "Di-Andre Caprice Davis"
              })
            })]
          }), _jsx("h3", {
            children: "Writing"
          }), _jsxs("ul", {
            className: "indented",
            children: [_jsx("li", {
              children: _jsx(_components.a, {
                href: "http://www.navneetalang.com",
                children: "Navneet Alang"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "http://www.eatingpopos.com",
                children: "Diane Chang"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://danfethke.com",
                children: "Daniel Pravit Fethke"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://griefbacon.substack.com",
                children: "Helena Fitzgerald"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://commercialtype.com",
                children: "Christian Schwartz"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://www.robinsloan.com",
                children: "Robin Sloan"
              })
            })]
          }), _jsx("h3", {
            children: "Thanks to"
          }), _jsxs("ul", {
            className: "indented",
            children: [_jsx("li", {
              children: _jsx(_components.a, {
                href: "https://www.kuihcafe.com",
                children: "Veronica Gan"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://www.kuihcafe.com",
                children: "Barnny Lim"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://www.helenlikesyou.com",
                children: "Helen Rosner"
              })
            }), "\n", _jsx("li", {
              children: _jsx(_components.a, {
                href: "https://lamorada.nyc",
                children: "Marco Saavedra"
              })
            })]
          })]
        }), _jsx("footer", {
          children: _jsx(_components.p, {
            children: "©2022 Commercial Type, all rights reserved.\nText and images on this site may not be reused\nwithout permission from their authors."
          })
        }), _jsxs("div", {
          className: "typefaces-used",
          children: [_jsx("h3", {
            children: "Typefaces used on this page:"
          }), _jsx("ul", {
            children: props.typefacesUsed
          })]
        })]
      })]
    });
  }
}
export default MDXContent;
