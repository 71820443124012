import type { NextPage } from "next"
import { articles } from "../data/articles"
import Link from "next/link"
import Header from "../components/Header"
import EditorsNote, { meta as EditorsNoteMeta } from "../content/toc.mdx"
import { useEffect, useState } from "react"
import classNames from "classnames"
import { get } from "lodash"
import SVG, { Props as SVGProps } from "react-inlinesvg"
import { Metadata } from "../components/Metadata"
import { LinkedFamilyName } from "../components/LinkedFamilyName"

const TOCLayout = ({ layoutKey }) => {
  const layout = EditorsNoteMeta.layouts[layoutKey]
  const getLayoutSpecificValue = (article: any, key: string) => {
    return get(article, ["layouts", layoutKey, key]) ?? get(article, key)
  }
  return (
    <main className="main">
      <div className="nameplate">
        <SVG src={layout.nameplate.src || ""} aria-label="Nameplate for The Food Issue" />
      </div>
      <div className={classNames("articles", { hasExcerpt: layout.excerpt })}>
        {articles.map((article, i) => (
          <Link key={article.meta.slug} href={`/${article.meta.slug}`}>
            <a className="card">
              <h3
                className="category"
                dangerouslySetInnerHTML={{
                  __html: getLayoutSpecificValue(article.meta, "category"),
                }}
              />
              <h2
                className="title"
                dangerouslySetInnerHTML={{
                  __html: getLayoutSpecificValue(article.meta, "title"),
                }}
              />
              <p
                className="excerpt"
                dangerouslySetInnerHTML={{
                  __html: getLayoutSpecificValue(article.meta, "excerpt"),
                }}
              />
              <p
                className="byline"
                dangerouslySetInnerHTML={{
                  __html: "By " + getLayoutSpecificValue(article.meta, "author"),
                }}
              />
            </a>
          </Link>
        ))}
      </div>
      <EditorsNote
        noteHeader={layout.noteHeader || undefined}
        typefacesUsed={layout.fonts.map((font) => (
          <li key={font}>
            <LinkedFamilyName family={font} />
          </li>
        ))}
      />
    </main>
  )
}

const Home: NextPage = () => {
  const [layoutIndex, setLayoutIndex] = useState(0)
  const layoutKey = Object.keys(EditorsNoteMeta.layouts)[layoutIndex % Object.keys(EditorsNoteMeta.layouts).length]
  const [currentLayoutKey, setCurrentLayoutKey] = useState(layoutKey)

  const [fontsReady, setFontsReady] = useState(false)
  useEffect(() => {
    document.fonts.ready.then(function () {
      setFontsReady(true)
    })
  }, [])
  useEffect(() => {
    // transition
    setFontsReady(false)
    window.setTimeout(() => {
      document.fonts.ready.then(function () {
        setFontsReady(true)
        setCurrentLayoutKey(layoutKey)
      })
    }, 250)
  }, [layoutKey])

  return (
    <>
      <Header
        onRandomize={() => {
          setLayoutIndex(layoutIndex + 1)
          window.scrollTo(0, 0)
        }}
      />
      <div className={classNames("container index", { fontsReady, fontsLoading: !fontsReady })} data-layout={currentLayoutKey}>
        <TOCLayout layoutKey={currentLayoutKey} />
      </div>
      {fontsReady === false && (
        <div className={classNames("container index loader", { fontsReady, fontsLoading: !fontsReady })} data-layout={layoutKey}>
          <TOCLayout layoutKey={layoutKey} />
        </div>
      )}

      <Metadata />
    </>
  )
}

export default Home
